import { TreeViewComponent } from '../@core/tree-view/tree-view.component';
import { ConfirmacionComponent } from '../@core/confirmacion/confirmacion.component';
import { FormDirective } from '../@core/directive/form.directive';
import { NgModule } from '@angular/core';

import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FlexLayoutModule } from '@angular/flex-layout';

const BASE_MODULES: any[] = [
  MatSliderModule,
  MatFormFieldModule,
  MatMenuModule,
  MatInputModule,
  MatIconModule,
  MatToolbarModule,
  MatButtonModule,
  MatCardModule,
  MatGridListModule,
  MatSidenavModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatSortModule,
  MatTreeModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatSelectModule,
  NgxMatSelectSearchModule,
  MatDatepickerModule,
  ScrollingModule,
  MatBadgeModule,
  MatChipsModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatStepperModule,
  FlexLayoutModule,
  MatRadioModule,
  MatChipsModule,
];

const COMPONENT: any[] = [
  FormDirective,
  ConfirmacionComponent,
  TreeViewComponent,
];

@NgModule({
  declarations: [...COMPONENT],
  imports: [...BASE_MODULES],

  exports: [...BASE_MODULES, ...COMPONENT],
})
export class TemaModule {}
