import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { AutenticacionService } from 'src/app/service/autenticacion.service';

@Injectable({ providedIn: 'root' })
export class SentryMonitoringService {

    constructor(
        private autenticacionService: AutenticacionService
    ) { }

    monitorUser(): void {
        const sesion = this.autenticacionService.obtenerSesion();
        Sentry.setUser(sesion);
    }
}
