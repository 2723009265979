<mat-toolbar color="primary">
  <span>{{ data.titulo }}</span>
</mat-toolbar>

<mat-card class="dialog">
  <mat-card-content class="dialog-container" fxLayout="column">
    <p [innerHTML]="data.mensaje"></p>
  </mat-card-content>
  <mat-card-actions
    class="dialog-footer"
    align="end"
    fxLayout="row"
    fxLayoutGap="8px"
  >
    <button (click)="cancelar()" mat-stroked-button>Cancelar</button>
    <button
      [style.margin]="0"
      (click)="aceptar()"
      mat-raised-button
      color="primary"
    >
      Aceptar
    </button>
  </mat-card-actions>
</mat-card>
