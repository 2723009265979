import { paths } from './app-paths';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PathResolveService } from './service/path-resolve-service.service';

const routes: Routes = [
  {
    path: paths.Pagina,
    loadChildren: () =>
      import('./pagina/pagina.module').then((m) => m.PaginaModule),
  },
  {
    path: paths.Remision,
    loadChildren: () =>
      import('./remisiones/remisiones.module').then((m) => m.RemisionesModule),
  },
  {
    path: paths.Seguimiento,
    loadChildren: () =>
      import('./seguimiento/seguimiento.module').then(
        (m) => m.SeguimientoModule
      ),
  },
  {
    path: paths.Gestoria,
    loadChildren: () =>
      import('./gestoria/gestoria.module').then(
        (m) => m.GestoriaModule
      ),
  },
 
  {
    path: paths.Gestoria,
    loadChildren: () =>
      import('./gestoria/gestoria-dispositivo/gestoria-dispositivo.module').then(
        (m) => m.GestoriaDispositivoModule
      ),
  },
  {
    path: paths.Sistema,
    loadChildren: () =>
      import('./sistema/sistema.module').then((m) => m.SistemaModule),
  },
  {
    path: '',
    redirectTo: paths.Pagina,
    pathMatch: 'full',
  },
  {
    path: '**',
    resolve: {
      path: PathResolveService,
    },
    redirectTo: 'Pagina/NoEncontrada',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
