import { environment } from 'src/environments/environment';
import { PaginaModule } from './pagina/pagina.module';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable, APP_INITIALIZER } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { SeguimientoDispositivoModule } from './gestoria/seguimiento-dispositivos/seguimiento-dispositivo.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AmbientePruebaComponent } from './ambiente-prueba/ambiente-prueba.component';
import { TemaModule } from './tema/tema.module';
import { AdBlockDetectadoComponent } from './adblock-detectado/adblock-detectado.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { SentryMonitoringService } from './@core/service/sentry-monitoring.service';
import { ConfiguracionImportacionClienteComponent } from './remisiones/remision/remision-editar/components/remision-paqueteria/configuracion-importacion-cliente/configuracion-importacion-cliente.component';
import { MatDialogModule } from '@angular/material/dialog';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { DatePipe } from '@angular/common';
import { MicroPodModule } from './shared/components/micro-pod/micro-pod.module';

registerLocaleData(localeEs);

import { GestoriaDestinatarioComponent } from './gestoria/gestoria-destinatario/gestoria-destinatario.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { GestoriaAsignacionDispositivoComponent } from './gestoria/remision/remision-editar/components/gestoria-asignacion-dispositivo/gestoria-asignacion-dispositivo.component';
import { SeguimientoDispositivosComponent } from './gestoria/seguimiento-dispositivos/seguimiento-dispositivos.component';
export function getToken() {
  if (sessionStorage.getItem('Token')) {
    // Token del usuario del sistema
    return sessionStorage.getItem('Token');
  } else {
    // TokenProspecto (Token del prospecto de cliente)
    return sessionStorage.getItem('TokenProspectoCliente');
  }
}
@NgModule({
  declarations: [
    AppComponent,
    AmbientePruebaComponent,
    AdBlockDetectadoComponent,
    ConfiguracionImportacionClienteComponent,
    GestoriaDestinatarioComponent,
    GestoriaAsignacionDispositivoComponent,
  ],

  imports: [
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    PaginaModule,
    AppRoutingModule,
    MatNativeDateModule,
    HttpClientModule,
    MatDialogModule,
    TemaModule,
    MicroPodModule,
    BrowserAnimationsModule,
    NgxMaskDirective, // Add the directive here
    NgxMaskPipe,
    ToastrModule.forRoot(),
  
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        allowedDomains: environment.whiteList,
      },
    }),
  ],
  providers: [
    // Sentry
    DatePipe , // Proveer DatePipe si es necesario
    // 
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    provideNgxMask(),
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    SentryMonitoringService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
