export const paths = {
  Pagina: 'Pagina',
  IniciarSesionRemision: 'Pagina/IniciarSesionRemision',
  Remision: 'Remision',
  Contactenos: 'Contactenos',
  Seguimiento: 'Seguimiento',
  Gestoria: 'Gestoria',
  Sistema: 'Sistema',
  Extensiones: 'Extensiones',
};
