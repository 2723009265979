import { ErrorService } from './error.service';
import { Respuesta } from './../@core/model/respuesta';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as DeviceDetector from 'device-detector-js';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class AutenticacionService {
  private url = `${environment.apiUrl}/Autenticacion`;

  constructor(private http: HttpClient, private errorService: ErrorService) { }

  get(): Observable<Respuesta> {
    return this.http.get<Respuesta>(this.url).pipe(
      map((result: Respuesta) => {
        return result;
      }),
      catchError((error, data) => {
        return this.errorService.handleError(error);
      })
    );
  }

  comprobarToken(token: string): Observable<Respuesta> {
    return this.http
      .post<Respuesta>(`${this.url}/ComprobarToken`, JSON.stringify(token), {
        headers: new HttpHeaders().set('content-type', 'application/json'),
      })
      .pipe(
        map((result: Respuesta) => {
          return result;
        }),
        catchError((error, data) => {
          return this.errorService.handleError(error);
        })
      );
  }

  cambiarContrasena(token: string, contrasena: string): Observable<Respuesta> {
    const body = JSON.stringify({
      Token: token,
      Contrasena: contrasena,
    });

    return this.http
      .post<Respuesta>(`${this.url}/CambiarContrasena`, body, {
        headers: new HttpHeaders().set('content-type', 'application/json'),
      })
      .pipe(
        map((result: Respuesta) => {
          return result;
        }),
        catchError((error, data) => {
          return this.errorService.handleError(error);
        })
      );
  }

  // getUsuario(): Observable<Respuesta> {
  //   return this.http.get<Respuesta>(`${environment.apiUrl}/Usuario`).pipe(
  //     map(
  //       (result: Respuesta) => {
  //         return result;
  //       }
  //     ),
  //     catchError((error, data) => {
  //       return this.errorService.handleError(error);
  //     }
  //     )
  //   )
  // }

  post(usuario: any): Observable<Respuesta> {
    const deviceDetector = new DeviceDetector();
    const data = deviceDetector.parse(navigator.userAgent);

    if (data.device)
      usuario.Dispositivo = `${data.device.type}${data.device.brand
        ? ', ' + data.device.brand + ' - ' + data.device.model
        : ''
        }`;
    else usuario.Dispositivo = 'No reconocido';

    if (data.os)
      usuario.SistemaOperativo = `${data.os.name} ${data.os.version} ${data.os.platform ? ' - ' + data.os.platform : ''
        }`;
    else usuario.SistemaOperativo = 'No reconocido';

    if (data.client)
      usuario.Navegador = `${data.client.name} ${data.client.version} `;
    else usuario.Navegador = 'No reconocido';

    const body = JSON.stringify(usuario);

    return this.http
      .post<Respuesta>(this.url, body, {
        headers: new HttpHeaders().set('content-type', 'application/json'),
      })
      .pipe(
        map((result: Respuesta) => {
          return result;
        }),
        catchError((error) => {
          return this.errorService.handleError(error);
        })
      );
  }

  // After login save token and other values(if any) in sessionStorage
  crearSesion(NombrePersonal: any, NombreUsuario: any, Token: any) {
    sessionStorage.setItem('NombrePersonal', NombrePersonal);
    sessionStorage.setItem('NombreUsuario', NombreUsuario);
    sessionStorage.setItem('Token', Token);

    const usuario = new JwtHelperService().decodeToken(Token);

    Sentry.setUser({
      id: usuario.primarysid,
      username: NombreUsuario,
      PersonalName: NombrePersonal
    });

  }

  // Checking if token is set
  existeSesion() {
    return sessionStorage.getItem('Token') != null;
  }

  obtenerSesion() {
    try {
      const usuarioJwt = new JwtHelperService().decodeToken(sessionStorage.getItem('Token')!);

      const usuario = {
        id: usuarioJwt.primarysid,
        NombrePersonal: sessionStorage.getItem('NombrePersonal'),
        NombreUsuario: sessionStorage.getItem('NombreUsuario')
      }

      return usuario;

    } catch (error) {
      return null;
    }
  }

  obtenerToken() {
    return sessionStorage.getItem('Token');
  }


  // After clearing sessionStorage redirect to login screen
  cerrarSesion() {
    sessionStorage.clear();
    // Sentry.setUser(null);
  }

  comprobarPermiso(idPermiso: string) {
    const listaPermiso = sessionStorage.getItem('ListaPermiso');
    return listaPermiso!.indexOf(idPermiso) > -1;
  }
}
