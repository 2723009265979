import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MicroPodComponent } from './micro-pod.component';
import {
  LazyElementModuleOptions,
  LazyElementsModule,
} from '@angular-extensions/elements';
import { environment } from 'src/environments/environment';

const options: LazyElementModuleOptions = {
  elementConfigs: [
    {
      tag: 'micro-pod',
      url: environment.mfePodUrl,
      preload: false,
    },
  ],
};

@NgModule({
  declarations: [MicroPodComponent],
  imports: [CommonModule, LazyElementsModule.forFeature(options)],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [MicroPodComponent],
})
export class MicroPodModule {}