<div class="center">
  <mat-card class="recovery-password">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title> Cambiar contraseña </mat-card-title>
    </mat-card-header>
    <form
      [formGroup]="cambiarContrasenaForm"
      focusInvalidInput
      (ngSubmit)="onSubmit(cambiarContrasenaForm.value)"
    >
      <mat-card-content fxLayout="column">
        <mat-form-field appearance="outline">
          <mat-label>Contraseña</mat-label>
          <input
            formControlName="Contrasena"
            matInput
            type="password"
            placeholder="Contraseña (*)"
            cdkFocusInitial
          />
          <mat-icon matSuffix>lock</mat-icon>
          <mat-error
            *ngIf="cambiarContrasenaForm?.controls['Contrasena']?.invalid"
          >
            {{
              obtenerMensajeError(cambiarContrasenaForm.controls["Contrasena"])
            }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Repetir contraseña</mat-label>
          <input
            formControlName="RepetirContrasena"
            [errorStateMatcher]="matcher"
            matInput
            type="password"
            placeholder="Repetir contraseña (*)"
            cdkFocusInitial
          />
          <mat-icon matSuffix>verified_user</mat-icon>
          <mat-error
            *ngIf="cambiarContrasenaForm?.hasError('contrasenaInvalida')"
          >
            Las contraseñas no coinciden
          </mat-error>
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions fxLayoutAlign="end" fxLayoutGap="8px">
        <button type="button" mat-stroked-button (click)="volverInicio()">
          Cancelar
        </button>
        <button type="submit" color="primary" mat-raised-button cdkFocusInitial>
          Guardar
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
