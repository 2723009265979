import { PaginaNoEncontradaComponent } from './pagina-no-encontrada/pagina-no-encontrada.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IniciarSesionRemisionComponent } from './iniciar-sesion-remision/iniciar-sesion-remision.component';
import { TemaPaginaModule } from './../tema/tema-pagina.module';
import { PaginaRoutingModule } from './pagina-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component';
import { PaginaComponent } from './pagina.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { NavigationComponent } from './layouts/navigation/navigation.component';

@NgModule({
  declarations: [
    IniciarSesionRemisionComponent,
    RecuperarContrasenaComponent,
    PaginaComponent,
    FooterComponent,
    NavigationComponent,
    PaginaNoEncontradaComponent,
  ],
  imports: [
    CommonModule,
    PaginaRoutingModule,
    TemaPaginaModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class PaginaModule {}
