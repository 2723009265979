<mat-sidenav-container fullscreen>
  <mat-sidenav
    class="sidenav-home"
    color="primary"
    #drawer
    mode="over"
    opened="false"
    fxHide.gt-sm="true"
  >
    <mat-toolbar color="primary">CargoWeb</mat-toolbar>
    <mat-nav-list dense>
      <mat-list-item
        (click)="drawer.close()"
        routerLinkActive="active-link"
        routerLink="/Pagina/Inicio"
      >
        <mat-icon>home</mat-icon>Inicio
      </mat-list-item>
      <mat-list-item
        (click)="drawer.close()"
        routerLinkActive="active-link"
        routerLink="/Pagina/IniciarSesionRemision"
      >
        <mat-icon>login</mat-icon>Iniciar sesión
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content fxLayout="column" fxFlexFill>
    <mat-toolbar
      class="header"
      color="primary"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <!-- <div fxShow="true" fxHide.gt-sm="true">
        <button mat-icon-button (click)="drawer.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div> -->

      <img class="logo-toolbar" src="/assets/icon/logo.png" />

      <!-- <div fxShow="true" fxHide.lt-md="true">
        <button mat-button routerLink="/Pagina/Inicio">Inicio</button>
        <button mat-button routerLink="/Pagina/IniciarSesionRemision">
          Iniciar sesión
        </button>
      </div> -->
    </mat-toolbar>
    <mat-sidenav-content fxFlex="100">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
    <app-footer fxFlex></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
