<div fxFlexFill fxLayout="column" class="content">
  <div fxFlex="100" fxFill class="background-flex50"></div>

  <!-- Login Mobile -->
  <div fxShow="true" fxHide.gt-xs="true" class="float" fxLayout="column">
    <input
      class="modules"
      type="checkbox"
      id="modules"
      name="modules"
      hidden
      [attr.checked]="!wicobidModule ? 'checked' : null"
    />
    <div fxFlexFill fxLayout="row" class="toggle-container">
      <label fxFlex="50" class="selector" for="modules">
        <mat-icon>receipt</mat-icon></label
      >
      <label fxFlex="50" for="modules">
        <mat-icon>local_shipping</mat-icon></label
      >
    </div>

    <div class="card-wrap">
      <ng-container *ngTemplateOutlet="renderEveryWhere"></ng-container>
    </div>
  </div>

  <!-- Login Desktop -->
  <div
    fxHide="true"
    fxShow.gt-xs="true"
    fxLayout="column"
    class="container float right-panel-active"
    id="container"
  >
    <div class="form-container wicobid-container">
      <ng-container *ngTemplateOutlet="renderEveryWhere"></ng-container>
    </div>
    <div class="form-container tracking-container">
      <ng-container *ngTemplateOutlet="renderEveryWhere"></ng-container>
    </div>

    <div class="overlay-container">
      <div class="overlay">
        <div fxLayout="column" class="overlay-panel overlay-right">
          <h2>¡Bienvenido de regreso!</h2>
          <p>Registra tu paquetería con nosotros desde cualquier lugar.</p>
          <button id="wicobid" mat-raised-button color="primary">
            Acceder a Wicobid
          </button>
        </div>
        <div fxLayout="column" class="overlay-panel overlay-left">
          <h2>¡Bienvenido de regreso!</h2>
          <p>Monitorea el estado de tus paquetes en cualquier momento.</p>
          <button id="tracking" mat-raised-button color="accent">
            Acceder a Seguimiento
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Login Template -->
<ng-template #renderEveryWhere>
  <form
    fxFlexFill
    [formGroup]="inicioSesionForm"
    focusInvalidInput
    (ngSubmit)="onSubmit(inicioSesionForm.value)"
  >
    <mat-card fxFlexFill fxLayout="column">
      <mat-card-header fxFlex fxLayoutAlign="center center">
        <mat-card-title fxLayoutAlign="center center">{{
          wicobidModule ? "WICOBID" : "SEGUIMIENTO"
        }}</mat-card-title>
        <mat-card-subtitle fxLayoutAlign="center center"
          >Acceso</mat-card-subtitle
        >
      </mat-card-header>
      <mat-card-content fxFlex fxLayout="column">
        <mat-form-field hintLabel="Máximo 100 caracteres" appearance="outline">
          <mat-label>Usuario</mat-label>
          <input
            #Usuario
            formControlName="Usuario"
            matInput
            placeholder="Escriba un nombre de usuario"
          />
          <mat-icon matSuffix>person</mat-icon>
          <mat-hint align="end">{{ Usuario?.value?.length! || 0 }}/100</mat-hint>
          <mat-error *ngIf="inicioSesionForm.controls['Usuario'].invalid">{{
            getErrorMessage(inicioSesionForm.controls["Usuario"])
          }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Contraseña</mat-label>
          <input
            formControlName="Contrasena"
            matInput
            [type]="hide ? 'password' : 'text'"
            placeholder="Escriba una contraseña"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-hint>Requerido</mat-hint>
          <mat-error *ngIf="inicioSesionForm.controls['Contrasena'].invalid">{{
            getErrorMessage(inicioSesionForm.controls["Contrasena"])
          }}</mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions fxFlex fxLayoutAlign="center center">
        <button
          type="submit"
          mat-stroked-button
          [color]="wicobidModule ? 'primary' : 'accent'"
        >
          Continuar
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</ng-template>
