import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component';
import { IniciarSesionRemisionComponent } from './iniciar-sesion-remision/iniciar-sesion-remision.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaginaComponent } from './pagina.component';
import { PaginaNoEncontradaComponent } from './pagina-no-encontrada/pagina-no-encontrada.component';

const routes: Routes = [
  {
    path: '',
    component: PaginaComponent,
    children: [
      {
        path: 'IniciarSesion',
        component: IniciarSesionRemisionComponent,
      },
      { path: 'RecuperarContrasena', component: RecuperarContrasenaComponent },
      { path: 'NoEncontrada', component: PaginaNoEncontradaComponent },
      { path: '', redirectTo: 'IniciarSesion', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaginaRoutingModule {}
