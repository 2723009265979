import { PasswordStateMatcher } from './../../@core/utilidad/password-matcher';
import { CodigoRespuesta } from './../../@core/enumerable/codigo-respuesta';
import { MensajeService } from './../../service/mensaje.service';
import { AutenticacionService } from './../../service/autenticacion.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  AbstractControl,
} from '@angular/forms';

@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.scss'],
})
export class RecuperarContrasenaComponent implements OnInit {
  private token: string;
  public cambiarContrasenaForm: any;
  public matcher = new PasswordStateMatcher();

  constructor(
    private route: ActivatedRoute,
    private service: AutenticacionService,
    private mensajeService: MensajeService,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) {
    this.token = this.route?.snapshot?.queryParams['token'];
  }

  ngOnInit() {
    this.cambiarContrasenaForm = this.formBuilder.group(
      {
        Contrasena: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
            ),
          ]),
        ],
        RepetirContrasena: [null],
      },
      {
        validator: this.validarContrasenasIguales.bind(this),
      }
    );

    this.comprobarToken();
  }

  private validarContrasenasIguales(group: UntypedFormGroup) {
    const contrasena = group.controls['Contrasena'].value;
    const repetirContrasena = group.controls['RepetirContrasena'].value;

    return contrasena === repetirContrasena
      ? null
      : { contrasenaInvalida: true };
  }

  async comprobarToken() {
    const resultado = await this.service.comprobarToken(this.token).toPromise();

    if (resultado?.exito === CodigoRespuesta.Advertencia) {
      this.mensajeService.generarToastr(
        resultado?.exito,
        '',
        resultado?.mensaje
      );
      this.router.navigate(['/Pagina/Inicio']);
    }
  }

  async onSubmit(formulario: any) {
    if (this.cambiarContrasenaForm.valid) {
      this.cambiarContrasenaForm.disable();

      const resultado = await this.service
        .cambiarContrasena(this.token, formulario.Contrasena)
        .toPromise();

      if (resultado?.exito === CodigoRespuesta.Exito) {
        this.mensajeService.generarToastr(
          resultado?.exito,
          null,
          resultado?.mensaje
        );
        this.router.navigate(['/Pagina/Inicio']);
      } else {
        this.mensajeService.generarToastr(
          resultado?.exito,
          '',
          resultado?.mensaje
        );
      }

      this.cambiarContrasenaForm.enable();
    }
  }

  obtenerMensajeError(control: AbstractControl) {
    return control.hasError('required')
      ? 'Este campo es requerido'
      : control.hasError('email')
      ? ' El correo electrónico es inválido.'
      : control.hasError('maxlength')
      ? 'Ha excedido el máximo de caracteres permitidos'
      : control.hasError('minlength')
      ? 'Aún no ha ingresado la cantidad mínima de caracteres'
      : control.hasError('contrasenaInvalida')
      ? 'Las contraseñas no coinciden'
      : control.hasError('validacionServidorFallida')
      ? 'Error al contactarse con el servidor, inténtelo de nuevo.'
      : control.hasError('nombreUsuarioInvalido')
      ? 'El nombre de usuario ya está en uso'
      : control.hasError('pattern')
      ? 'La contraseña deberá contener una mayúscula, una minúscula, un número y caracteres especiales'
      : '';
  }

  volverInicio() {
    this.router.navigate(['/Pagina/IniciarSesion']);
  }
}
