import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ambiente-prueba',
  templateUrl: './ambiente-prueba.component.html',
  styleUrls: ['./ambiente-prueba.component.scss']
})
export class AmbientePruebaComponent implements OnInit {
  production = environment.production;

  constructor() { }

  ngOnInit() {
  }

}
