import { CodigoRespuesta } from '../../@core/enumerable/codigo-respuesta';
import { MensajeService } from '../../service/mensaje.service';
import { AutenticacionService } from '../../service/autenticacion.service';
import { Component, AfterViewInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-iniciar-sesion-remision',
  templateUrl: './iniciar-sesion-remision.component.html',
  styleUrls: ['./iniciar-sesion-remision.component.scss'],
})
export class IniciarSesionRemisionComponent implements AfterViewInit {
  title = 'Iniciar sesión';

  public hide = true;

  public wicobidModule = true;

  public inicioSesionForm;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: AutenticacionService,
    private mensajeService: MensajeService,
    private router: Router
  ) {
    this.inicioSesionForm = this.formBuilder.group({
      Usuario: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(100),
          Validators.minLength(2),
        ]),
      ],
      Contrasena: [null, Validators.required],
    });
  }

  getErrorMessage(control: AbstractControl) {
    return control.hasError('required')
      ? 'Este campo es requerido'
      : control.hasError('email')
      ? ' El correo electrónico es inválido.'
      : control.hasError('maxlength')
      ? 'Ha excedido el máximo de caracteres permitidos'
      : control.hasError('minlength')
      ? 'Aún no ha ingresado la cantidad mínima de caracteres'
      : '';
  }

  ngAfterViewInit() {
    if (this.service.existeSesion()) {
      this.wicobidModule
        ? this.router.navigate(['/Remision/Principal'])
        : this.router.navigate(['/Seguimiento/Credito']);
    }

    const wicobidButton = document.getElementById('wicobid');
    const trackingButton = document.getElementById('tracking');
    const container = document.getElementById('container');

    wicobidButton?.addEventListener('click', () => {
      this.wicobidModule = true;
      this.inicioSesionForm.reset();
      container?.classList.add('right-panel-active');
    });

    trackingButton?.addEventListener('click', () => {
      this.wicobidModule = false;
      this.inicioSesionForm.reset();
      container?.classList.remove('right-panel-active');
    });

    const modulesCheckbox = document.getElementById('modules');
    modulesCheckbox?.addEventListener('change', (e: any) => {
      this.wicobidModule = !e.target.checked;
      this.wicobidModule
        ? container?.classList.add('right-panel-active')
        : container?.classList.remove('right-panel-active');
    });
  }

  async onSubmit(usuario: any) {
    if (this.inicioSesionForm.valid) {
      this.inicioSesionForm.disable();

      const resultado = await this.service.post(usuario).toPromise();

      if (resultado?.exito === CodigoRespuesta.Exito) {
        this.onValidateCredentials(resultado);
      } else {
        this.mensajeService.generarToastr(
          resultado?.exito,
          '',
          resultado?.mensaje
        );
      }

      this.inicioSesionForm.enable();
    }
  }

  private onValidateCredentials(resultado: any) {
    const redireccionModuloRemision = () => {
      this.onCreateUserSession(resultado);
      this.router.navigate(['/Remision/Principal']);
    };

    const redireccionModuloSeguimiento = () => {
      this.onCreateUserSession(resultado);
      this.router.navigate(['/Seguimiento/Credito']);
    };

    const redireccionModuloRS = () => {
      this.onCreateUserSession(resultado);
      this.wicobidModule
        ? this.router.navigate(['/Remision/Principal'])
        : this.router.navigate(['/Seguimiento/Credito']);
    };

    const error = () => {
      this.mensajeService.generarToastr(
        2,
        '',
        'Su usuario no tiene permiso para acceder a esta funcionalidad'
      );
    };

    const handleModule = {
      Remision: redireccionModuloRemision,
      Seguimiento: redireccionModuloSeguimiento,
      RS: redireccionModuloRS,
      Error: error,
    };

    handleModule[
      resultado.dato.tienePermisoRemision &&
      !resultado.dato.tienePermisoSeguimiento &&
      this.wicobidModule
        ? 'Remision'
        : !resultado.dato.tienePermisoRemision &&
          resultado.dato.tienePermisoSeguimiento &&
          !this.wicobidModule
        ? 'Seguimiento'
        : resultado.dato.tienePermisoRemision &&
          resultado.dato.tienePermisoSeguimiento
        ? 'RS'
        : 'Error'
    ]();
  }

  private onCreateUserSession(resultado: any) {
    this.mensajeService.generarToastr(resultado.exito, '', resultado.mensaje);

    const { nombrePersonal, nombreUsuario, token } = resultado.dato.usuarioAPI;

    this.service.crearSesion(nombrePersonal, nombreUsuario, token);
  }
}
