<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button type="button" mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
            (change)="checklistSelection.toggle(node);">{{node.item}}</mat-checkbox>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
        <button type="button" mat-icon-button disabled></button>
        <mat-form-field>
            <input matInput #itemValue placeholder="New item...">
        </mat-form-field>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button type="button" mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
            (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
    </mat-tree-node>
</mat-tree>