<div class="box">
  <h2>404</h2>
  <p>Página no encontrada</p>
  <p class="justify">
    La página que estabas buscando no existe. Es posible que haya escrito mal la
    dirección o que la página se haya movido.
  </p>

  <div class="row">
    <a id="tracking" mat-raised-button color="accent" href="/">
      Volver al inicio
    </a>
  </div>
</div>
